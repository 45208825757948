import {PowderguideApiInterface} from "./powderguide-api";

interface ConditionsReport {
    uuid: string,
    title: string,
    // ...
}

interface Pagination {
    currentPage: number;
    nextPage: number | null;
    previousPage: number | null;
    lastPage: number;
    firstPage: number;
    totalResults: number;
    itemsPerPage: number;
    surrounding: number[];

    loadPage(page: number): Promise<void>;
    loadNextPage(): Promise<void>;
    loadPreviousPage(): Promise<void>;
    loadFirstPage(): Promise<void>;
    loadLastPage(): Promise<void>;

    hasNextPage: boolean;
    hasPreviousPage: boolean;
    isLastPage: boolean;
    isFirstPage: boolean;
}

export class PowderguideConditionsReport implements Pagination {

    private static readonly SURROUNDING_PAGES = 2;

    public conditionsReports: ConditionsReport[] = [];
    public currentPage: number = -1;
    public nextPage: number | null = null;
    public previousPage: number | null = null;
    public lastPage: number = 1;
    public firstPage: number = 1;
    public totalResults: number = 0;
    public itemsPerPage: number = 0;
    public surrounding: number[] = [1];


    public loading: boolean = true;

    constructor(
        private api: PowderguideApiInterface
    ) {
    }

    public async init(pageSize: number): Promise<void> {
        this.itemsPerPage = pageSize;
        await this.loadPage(1);
    }

    public async loadPage(page: number): Promise<void> {
        if (page === this.currentPage) {
            return;
        }
        
        this.loading = true;
        this.currentPage = page;

        const url = `api/v1/conditions-report?limit=${this.itemsPerPage}&page=${page}`;
        const response = await this.api.get(url);

        this.conditionsReports = response.data;
        this.updatePagination(page, response)

        this.loading = false;
    }

    public async loadNextPage(): Promise<void> {
        if (this.nextPage) {
            await this.loadPage(this.nextPage);
        }
    }

    public async loadPreviousPage(): Promise<void> {
        if (this.previousPage) {
            await this.loadPage(this.previousPage);
        }
    }

    public async loadFirstPage(): Promise<void> {
        await this.loadPage(this.firstPage);
    }

    public async loadLastPage(): Promise<void> {
        await this.loadPage(this.lastPage);
    }

    public get hasNextPage(): boolean {
        return this.nextPage !== null;
    }

    public get hasPreviousPage(): boolean {
        return this.previousPage !== null;
    }

    public get isLastPage(): boolean {
        return this.currentPage === this.lastPage;
    }

    public get isFirstPage(): boolean {
        return this.currentPage === this.firstPage;
    }

    private updatePagination(page: number, response: any) {
        this.currentPage = page;
        this.nextPage = response.next_page_url ? page + 1 : null;
        this.previousPage = response.prev_page_url ? page - 1 : null;
        this.lastPage = response.last_page;
        this.totalResults = response.total;

        const min = Math.max(this.firstPage, this.currentPage - PowderguideConditionsReport.SURROUNDING_PAGES);
        const max = Math.min(this.lastPage, this.currentPage + PowderguideConditionsReport.SURROUNDING_PAGES);
        this.surrounding = [];
        for (let i = min; i <= max; i++) {
            this.surrounding.push(i);
        }
    }

}
