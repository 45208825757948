import {PowderguideApiTokenStorage} from "./api/powderguide-api-token-storage";
import {PowderguideAuthentication} from "./api/powderguide-authentication";

export class EmbeddedIframe {
    private iframe: HTMLIFrameElement;
    private loginMessage: HTMLElement;
    private loadingIndicator: HTMLElement;
    private iframeSrc: string;
    private iframePath: string;
    private wrapperUri: string;
    private requiresLogin: boolean;
    private sizingMode: 'auto'|'fullscreen' = 'auto';

    constructor(private wrapper: HTMLDivElement, private auth: PowderguideAuthentication) {
        this.loginMessage = wrapper.querySelector('[data-embedded-iframe\\.login-message]')
        this.loadingIndicator = wrapper.querySelector('[data-embedded-iframe\\.loading-indicator]')
        this.iframe = document.createElement('iframe');
        this.iframe.setAttribute('frameborder', '0');
        this.iframe.style.width = '100%';
        this.sizingMode = wrapper.getAttribute('data-embedded-iframe.sizing-mode') === 'fullscreen' ? 'fullscreen' : 'auto';

        this.iframeSrc = wrapper.getAttribute('data-embedded-iframe');
        this.iframePath = wrapper.getAttribute('data-embedded-iframe.path');
        this.wrapperUri = wrapper.getAttribute('data-embedded-iframe.wrapper-uri');
        this.requiresLogin = wrapper.getAttribute('data-embedded-iframe.requires-login') === 'true';

        this.auth.init({ }).then(() => this.init());
    }

    private init() {
        if (this.requiresLogin && this.auth.user === null) {
            this.loadingIndicator.remove();
            this.loginMessage.style.display = 'block';
            return;
        }

        const listener = () => {
            this.iframe.contentWindow.removeEventListener('DOMContentLoaded', listener);
            this.loadingIndicator.remove();
            this.loginMessage.remove();

            this.iframe.style.display = 'block';

            this.initIframe()
        };

        this.iframe.src = `${this.iframeSrc}/${this.iframePath}`
        this.wrapper.appendChild(this.iframe);
        this.iframe.contentWindow.addEventListener('DOMContentLoaded', listener);
        this.iframe.contentWindow.addEventListener('powderguide-embedded-iframe-navigate', (event: any) => {
            window.location.href = event.detail.url;
        })
        this.iframe.contentWindow.addEventListener('powderguide-embedded-iframe-fullscreen', () => {
            this.sizingMode = 'fullscreen';
            this.updateSize();
            setTimeout(() => this.updateSize(), 400);
        })
        this.iframe.contentWindow.addEventListener('powderguide-embedded-iframe-autosize', () => {
            this.sizingMode = 'auto';
            this.updateSize();
            setTimeout(() => this.updateSize(), 400);
        })
        this.updateSize();
    }

    private initIframe() {
        this.updateSize();
        setTimeout(() => this.updateSize(), 100);
        setTimeout(() => this.updateSize(), 200);
        setTimeout(() => this.updateSize(), 400);
        setTimeout(() => this.updateSize(), 800);
        setTimeout(() => this.updateSize(), 1600);
        setTimeout(() => this.updateSize(), 3200);
        this.iframe.addEventListener('load', () => this.updateSize());
        this.updateLocation(this.iframe.contentWindow.location.pathname);
        this.onIframeLocationChange(path => {
            this.updateLocation(path)
            this.updateSize();
            setTimeout(() => this.updateSize(), 100);
            setTimeout(() => this.updateSize(), 400);
            setTimeout(() => this.updateSize(), 800);
            setTimeout(() => this.updateSize(), 1600);
            setTimeout(() => this.updateSize(), 3200);
        });
    }

    private onIframeLocationChange(callback: (path: string) => void) {
        const pushState = this.iframe.contentWindow.history.pushState;
        this.iframe.contentWindow.history.pushState = function(_data, _unused, path) {
            callback(`${path}`);
            pushState.apply(this, arguments);
        }
    }

    private updateLocation(path: string) {
        if (path.indexOf(this.iframeSrc) === -1) {
            // External navigation
            window.location.href = path;
            return;
        }

        path = path.replace(this.iframeSrc, '');
        path = path.replace(/(^\/+|\/+$)/g, '');
        this.iframePath = path;
        window.history.pushState({}, '', `/${this.wrapperUri}/${path}`);
    }

    private updateSize() {
        this.iframe.contentWindow.document.documentElement.style.overflow = 'hidden';

        switch (this.sizingMode) {
            case 'auto':
                this.iframe.style.removeProperty('position');
                this.iframe.style.removeProperty('top');
                this.iframe.style.removeProperty('left');
                this.iframe.style.removeProperty('z-index');

                let minHeight = window.innerHeight;

                const header = document.querySelector('header');
                minHeight -= header.clientHeight;
                minHeight -= parseInt(window.getComputedStyle(header).marginBottom);

                document.querySelectorAll('footer').forEach(el => minHeight -= el.clientHeight);

                const content = document.querySelector('#content');
                if (content) {
                    const contentStyle = window.getComputedStyle(content);
                    minHeight -= parseInt(contentStyle.paddingTop);
                    minHeight -= parseInt(contentStyle.paddingBottom);
                }


                this.iframe.style.minHeight = minHeight + 'px';
                this.iframe.style.height = '0px';
                this.iframe.style.height = this.iframe.contentWindow.document.documentElement.scrollHeight + 'px';
                break;

            case 'fullscreen':
                this.iframe.style.removeProperty('min-height');
                this.iframe.style.position = 'fixed';
                this.iframe.style.top = '0';
                this.iframe.style.left = '0';
                this.iframe.style.height = '100vh';
                this.iframe.style.zIndex = '99999';
        }
    }
}
