import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'
import morph from '@alpinejs/morph'
import focus from '@alpinejs/focus'
import './components/iframe-auto-size';
import './components/gallery';
import './components/carousel';
import {
    initializePowderguide,
    killOldServiceWorkers,
} from "./api";
import { EmbeddedIframe } from "./embedded-iframe";
import {Linkify} from "./linkify";
import Choices from 'choices.js'

// Global get CSRF token function (used by forms).
window.getToken = async () => {
    return await fetch('/!/statamic-peak-tools/dynamic-token/refresh')
        .then((res) => res.json())
        .then((data) => {
            return data.csrf_token
        })
        .catch((error) => {
            this.error = 'Something went wrong. Please try again later.'
        })
}

function initialize() {
    window.Choices = Choices;

    killOldServiceWorkers();
    const {
        powderguideApi,
        powderguideAuthentication,
        powderguideComments,
        powderguideRegistration,
        powderguideConditionsReports,
        powderguidePasswordReset,
    } = initializePowderguide();
    const linkify = new Linkify();

    // Call Alpine.
    window.Alpine = Alpine
    Alpine.plugin(collapse)
    Alpine.plugin(morph)
    Alpine.plugin(focus)
    Alpine.directive('linkify', el => linkify.parseFromDomNode(el))
    Alpine.store('powderguideApiAuthentication', powderguideAuthentication)
    Alpine.store('powderguideApi', powderguideApi)
    Alpine.store('powderguideComments', powderguideComments)
    Alpine.store('powderguideRegistration', powderguideRegistration)
    Alpine.store('powderguideConditionsReports', powderguideConditionsReports)
    Alpine.store('powderguidePasswordReset', powderguidePasswordReset)

    for (const embeddedIframe of document.querySelectorAll('[data-embedded-iframe]')) {
        new EmbeddedIframe(embeddedIframe, powderguideAuthentication);
    }

    Livewire.start();
}

const isStaticCache = document.querySelector('[data-nocache]') !== null;
if (isStaticCache) {
    document.addEventListener('statamic:nocache.replaced', () => initialize())
} else {
    initialize();
}
